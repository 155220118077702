<template>
  <div>
    <div class="Section" v-wechat-title="this.clickVal+ '-药百万'">
      <div class="breadcrumbNav">
        <span class="navName">当前位置 : </span>
        <div class="activeNav">
          <span>
            <router-link to="/home"
            >{{ clickVal }} </router-link>
          </span>
        </div>
      </div>
     
      <div class="goodsList" v-if="searchProducts.length>0">
        <!-- <div v-if="total==0">
          <p class="NullBox"></p>
        </div>
        <div v-else> -->
             <shopItem
              v-for="(item, index) in searchProducts"
              :key="index"
              class="goods"
              :info="item"
            ></shopItem>
        <!-- </div> -->
      </div>
      <div class="goodsList" v-else>
         <p class="NullBox">敬请期待</p>
      </div>
    </div>
  </div>
</template>

<script>
const shopItem = () => import("components/index/shopItem.vue");
import {getBrandUnionById} from "api/product.js";
export default {
  name: "BrandUnionList",
  data() {
    return {
       clickVal:'', //  面包屑 初始，一级分类选中值为空
      clickID: '',    //一级分类ID 
      searchProducts: [], //初始商品数据
     

    };
  },
  computed: {},
  created() {
                this.getBrandUnionList()
                     this.clickVal = this.$route.query.name;
                    this.clickID=''

  },
  methods: {
        //  品牌id列表页
        getBrandUnionList(){
            // 显示loading
            this.$loading({ fullscreen: true ,background:'#ffffff45'})
                getBrandUnionById(this.$route.query.brandId).then(res=>{
                    if(res.data.data.length>0){
                      const records = res.data.data;
                      this.searchProducts = records;
                    }
                    console.log(this.searchProducts)
                        // 关闭loading
                    this.$loading().close();
                })
        },

   
  },
   watch: {
    
  },
  components: {
    shopItem
  },

  mounted() {},
  updated() {},
};
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ed3129;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #ed3129;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}

@import "~style/index.less";
/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.Section {
  width: 1200px;
  margin: 10px auto 0;
  margin-top: 10px;
  background: #f6f6f6;
  padding-bottom: 40px;
  overflow: hidden;
  .breadcrumbNav {
    padding: 8px 15px 15px 0;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 20px;
    .navName {
      float: left;
      color: #999;
      padding-right: 8px;
    }
    .activeNav {
      float: left;
      color: #999;
      line-height: 20px;
      span {
        color: #551a8b;
        padding-right: 8px;
        float: left;
      }
      b {
        float: left;
      }
      .checkUl {
        float: left;
        padding-left: 8px;
        li {
          padding: 0 5px;
          height: 20px;
          line-height: 18px;
          border: 1px solid #ff9999;
          color: #ff9999;
          font-size: 12px;
          background: #fff;
          margin-right: 8px;
          float: left;
        }
      }
    }
  }
  .sortBox {
    box-sizing: content-box;
    background-color: #ffffff;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    width: 100%;
    float: left;
    .sortUlbox:last-child {
      border-bottom: none;
    }
    .sortUlbox {
      width: 100%;
      padding: 8px 15px;
      float: left;
      line-height: 40px;
      border-bottom: 1px dashed #ccc;
      .ulTitle {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        width: 136px;
        text-align: center;
        float: left;
      }
      .sortUl {
        width: calc(100% - 136px);
        float: left;
        li {
          float: left;
          margin-right: 40px;
          color: #666;
          font-size: 14px;
            cursor: pointer;
            &:hover{
                color: @theme;
            }
        }
        li.active {
          color: @theme;
        }
      }
    }
  }

//   .filterUl {
//     padding: 8px 15px;
//     margin-bottom: 17px;
//     border: 1px solid #eeeeee;
//     box-sizing: content-box;
//     background-color: #ffffff;
//     height: 20px;
//     line-height: 20px;
//     width: 100%;
//     float: left;
//     li.active {
//       color: #ff9999;
//     }
//     li {
//       float: left;
//       margin-right: 40px;
//       color: #666666;
//       font-size: 12px;
//       position: relative;
//         cursor: pointer;
//             &:hover{
//                 color: @theme;
//                 .el-checkbox{
//                   color: @theme;
//                 }
               
//             }
//       i {
//         font-weight: bolder;
//         font-size: 14px;
//          color: #666666;
//         &.active{
//            color: @theme;
//         }
//         &.iconTop{
//             position:absolute;
//             right: -18px;
//             top: 0;
//         }
//         &.iconBottom{
//             position:absolute;
//             right: -18px;
//             bottom: 0;
//         }
//       }
//     }
//   }
  .goodsList {
    height: auto;
    // overflow: hidden;
    float: left;
    min-height: 228px;
    background: #f6f6f6;
    width: 100%;
    .goods:nth-child(5n + 0) {
      margin-right: 0px;
    }
    .NullBox{
      font-size: 30px;
      text-align: center;
      width: 100%;
      line-height: 300px;
    height: 300px;
      background:url("data:image/svg+xml,%3Csvg width='130' height='130' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill='%23a2a9b6' font-family='system-ui, sans-serif' text-anchor='middle' transform='rotate(-45, 100 100)' dominant-baseline='middle'%3E药百万%3C/text%3E%3C/svg%3E");
      }
  }
}
</style>
